<template>
<div><h2 class="text-danger">Welcome</h2>
<!--  <b-button variant="danger">Button</b-button>-->
</div>
</template>

<script>
import {BButton} from "bootstrap-vue-3";
export default {
  components: {BButton}
};
</script>

<style scoped>


</style>
