<template>

  <div class="row m-0 p-0 fixed-bottom" style="width: 100%;">
    <div class="btn-group p-0" role="group" aria-label="Basic mixed styles example">
      <router-link to="/" class="btn btn-danger"  data-bs-toggle="tooltip"
         data-bs-placement="top" title="Home Page"><i class="fas fa-home"></i> 回到主页</router-link>

      <div class="btn-group" role="group">
        <button id="btnGroupDrop1" type="button" class="btn btn-primary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
          <i class="fas fa-bars"></i>
        </button>
        <ul class="dropdown-menu" aria-labelledby="btnGroupDrop1">
          <router-link class="dropdown-item py-2"
             v-for="(item, i) in items"
             :key="i"
             :to=item.to
          >
            <i class="fas fa-sign-out"></i> {{ item.text }}
          </router-link>

          <a class="dropdown-item py-2" href="/logout">
            <i class="fas fa-sign-out"></i> 退出系统
          </a>
          <hr class="dropdown-divider">
          <a class="dropdown-item py-2" v-if="isAuthenticated">
            <i class="fas fa-sign-out"></i> {{ fullname }}
          </a>
        </ul>
      </div>

    </div>
  </div>

  <div>

      <router-view></router-view>

  </div>

</template>

<script>

import {BListGroup} from "bootstrap-vue-3";

export default {
  name: 'App',
  components: {BListGroup},
  data: () => ({
    isAuthenticated: null,
    fullname: null,
    drawer: null,
    items: [
      {text: 'Keywords', icon: 'mdi-folder', to: '/keywords'},
      {text: 'Tasks', icon: 'mdi-folder', to: '/tasks'},
      {text: 'Todo', icon: 'mdi-star', to: '/todo'},
      {text: 'Note', icon: 'mdi-star', to: '/note'},
      {text: 'Blog', icon: 'mdi-star', to: '/blog'},

    ],
  }),
  created() {
    this.isAuthenticated = localStorage.getItem('token');
    this.fullname = localStorage.getItem('fullname');
    if (!this.isAuthenticated) {
      this.$router.push('/login');
    }
  }
}
</script>
