import { createRouter, createWebHistory } from 'vue-router'
import TheHome from '../components/TheHome.vue'
import LoginView from '../components/LoginView.vue'
import RegisterView from '../components/RegisterView.vue'
import KeywordCard from "../components/keyword/KeywordCard.vue";
import TaskCard from "../components/task/TaskCard";

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'home',
      component: TheHome
    },
    {
      path: '/login',
      name: 'login',
      component: LoginView
    },
    {
      path: '/logout',
      name: 'logout',
      component: LoginView
    },
    {
      path: '/register',
      name: 'register',
      component: RegisterView
    },
    {
      path: '/keywords',
      name: 'keywords',
      component: KeywordCard
    },
    {
      path: '/tasks',
      name: 'tasks',
      component: TaskCard
    }
  ]
})

export default router
