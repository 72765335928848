<template>
<keyword-new></keyword-new>
<keyword-list></keyword-list>

</template>

<script>

import KeywordList from "./KeywordList.vue";
import KeywordNew from "./KeywordNew.vue";


export default {
  name:"KeywordCard",
  components: {KeywordNew, KeywordList},


}

</script>

<style scoped>

</style>
