<template>
<task-new></task-new>
<task-list></task-list>

</template>

<script>

import TaskList from "./TaskList.vue";
import TaskNew from "./TaskNew.vue";


export default {
  name:"TaskCard",
  components: {TaskNew, TaskList},


}

</script>

<style scoped>

</style>
