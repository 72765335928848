import { ref } from 'vue'
import axios from "axios"
import { useRouter } from 'vue-router'


export default function useKeywords(){
    const keywords = ref([])
    const router = useRouter()
    const errors = ref('')
    const isAuthenticated = localStorage.getItem('token');
    const fullname = localStorage.getItem('fullname');

    const getKeywords = async () => {
        let response = await axios.get(axios.defaults.baseUrl + 'keywords',
            {
                headers: {
                    Authorization: "Bearer " + isAuthenticated,
                    Accept: 'application/json'
                },
            })
        keywords.value = response.data.data;

    }

    const storeKeyword = async (data) => {
        errors.value = ''
        try {
            await axios.post(axios.defaults.baseUrl + 'keywords', data,{
                headers: {
                    Authorization: "Bearer " + isAuthenticated,
                    Accept: 'application/json'
                },
            })
            window.location.reload()
        } catch (e) {
            if (e.response.status === 422) {
                errors.value = e.response.data.errors
            }
            //If error happen, autofocus on input again
            document.getElementById("keyword").focus();
        }
    }

    const destroyKeyword = async (id) => {
        await axios.delete(axios.defaults.baseUrl + 'keywords/' + id,
            {
                headers: {
                    Authorization: "Bearer " + isAuthenticated,
                    Accept: 'application/json'
                },
            })
    }

    return {
        keywords,
        errors,
        getKeywords,
        storeKeyword,
        destroyKeyword
    }

}
