<template>

  <div class="p-2">

      <b-list-group v-for="task in tasks">

        <b-list-group-item class="fs-4">
          <b-form-checkbox @click="updateTask(task.id)">{{ task.title }}</b-form-checkbox>
          <b-button style="position: absolute; right: 8px; top: 8px;" @click="deleteTask(task.id)">Del </b-button>
        </b-list-group-item>
      </b-list-group>

    <hr>
    <b-list-group v-for="task in f_tasks">

      <b-list-group-item class="fs-4">
        <b-form-checkbox @click="updateTask(task.id)"><span class="text-decoration-line-through">{{ task.title }}</span></b-form-checkbox>
        <b-button style="position: absolute; right: 8px; top: 8px;" @click="deleteTask(task.id)">Del </b-button>
      </b-list-group-item>
    </b-list-group>
  </div>
</template>

<script>
import useTasks from "../../composables/task";
import {onMounted} from "vue";
import {BButton, BFormCheckbox, BListGroup, BListGroupItem} from "bootstrap-vue-3";
export default {
  name: "TaskList",
  components: {BFormCheckbox, BButton, BListGroupItem, BListGroup},
  setup(){
    const { tasks,f_tasks, getTasks,destroyTask,finishTask } = useTasks()
    onMounted(getTasks)

    const deleteTask = async (id) => {
      if (!window.confirm('Are you sure?')){
        return
      }
      await destroyTask(id);
      await getTasks();
    }

    const updateTask = async (id) => {

      await finishTask(id);
      await getTasks();
    }
    return {
      tasks,
      f_tasks,
      deleteTask,
      updateTask
    }

  }

}
</script>

<style scoped>

</style>
