import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import {BootstrapVue3,BootstrapVueIcons }from 'bootstrap-vue-3'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css'

import { loadFonts } from './plugins/webfontloader'
import './axios.js'
loadFonts()

createApp(App)
  .use(router)
  .use(BootstrapVue3)
  .use(BootstrapVueIcons)
  .mount('#app')
