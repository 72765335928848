import { ref } from 'vue'
import axios from "axios"
import { useRouter } from 'vue-router'


export default function useTasks(){
    const tasks = ref([])
    const f_tasks = ref([])
    const router = useRouter()
    const errors = ref('')
    const isAuthenticated = localStorage.getItem('token');
    const fullname = localStorage.getItem('fullname');

    const getTasks = async () => {
        let response = await axios.get(axios.defaults.baseUrl + 'tasks',
            {
                headers: {
                    Authorization: "Bearer " + isAuthenticated,
                    Accept: 'application/json'
                },
            })
        tasks.value = response.data.data[0].tasks;
        f_tasks.value = response.data.data[1].tasks; //finished tasks

    }

    const storeTask = async (data) => {
        errors.value = ''
        try {
            await axios.post(axios.defaults.baseUrl + 'tasks', data,{
                headers: {
                    Authorization: "Bearer " + isAuthenticated,
                    Accept: 'application/json'
                },
            })
            window.location.reload()
        } catch (e) {
            if (e.response.status === 422) {
                errors.value = e.response.data.errors
            }
            //If error happen, autofocus on input again
            document.getElementById("title").focus();
        }
    }

    const destroyTask = async (id) => {
        await axios.delete(axios.defaults.baseUrl + 'tasks/' + id,
            {
                headers: {
                    Authorization: "Bearer " + isAuthenticated,
                    Accept: 'application/json'
                },
            })
    }

    const finishTask = async (id) => {
        try {
            await axios.get(axios.defaults.baseUrl + 'tasks/finish/' + id,
                {
                    headers: {
                        Authorization: "Bearer " + isAuthenticated,
                        Accept: 'application/json'
                    },
                })
            window.location.reload()
        }catch (e) {
            if (e.response.status === 422) {
                errors.value = e.response.data.errors
            }

        }
    }

    return {
        tasks,
        f_tasks,
        errors,
        getTasks,
        storeTask,
        destroyTask,
        finishTask
    }

}
