<template>

  <div class="p-2">
    <b-card v-for="keyword in keywords" class="mb-4 shadow">
        <b-card-title>{{ keyword.date }}</b-card-title>
        <b-button v-for="note in keyword.keywords"
                  class="m-1 py-0"
                  @click="deleteKeyword(note.id)"
        >
          {{ note.keyword }}
        </b-button>

    </b-card>

  </div>
</template>

<script>
import useKeywords from "../../composables/keyword";
import {onMounted} from "vue";
export default {
  name: "KeywordList",
  setup(){
    const { keywords, getKeywords,destroyKeyword } = useKeywords()
    onMounted(getKeywords)

    const deleteKeyword = async (id) => {
      if (!window.confirm('Are you sure?')){
        return
      }
      await destroyKeyword(id);
      await getKeywords();
    }
    return {
      keywords,
      deleteKeyword,
    }

  }

}
</script>

<style scoped>

</style>
