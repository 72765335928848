<template>
  <form @submit.prevent="saveKeyword" class="pa-0 rounded">
    <div v-if="errors">
      <div v-for="(v, k) in errors" :key="k" class="text-danger">
        <p v-for="error in v" :key="error" class="text-sm">
          {{ error }}
        </p>
      </div>
    </div>
    <b-input-group prepend="Keyword" class="p-2">
      <b-col>
        <b-form-input ref="keyword" v-model="form.keyword" placeholder="Enter a Keyword"></b-form-input>
      </b-col>
      <b-input-group-append>
        <b-button variant="outline-success" v-on:click="saveKeyword">Go</b-button>
      </b-input-group-append>
    </b-input-group>
  </form>

</template>

<script>
import {reactive} from "vue";
import useKeywords from "../../composables/keyword";

export default {
  name: "KeywordNew",
  setup() {
    const form = reactive({
      'user_id': '1',
      'keyword': '',
    })

    const { errors, storeKeyword } = useKeywords()

    const saveKeyword = async () => {
      await storeKeyword({...form});
    }

    return {
      errors,
      form,
      saveKeyword
    }

  },
  mounted() {
    this.$refs["keyword"].focus();
  },
}
</script>

<style scoped>

</style>
